*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 12px;
	--color-text: #f0f0f0;
	--color-bg: #0f0e0e;
	--color-link: #aaa;
	--color-link-hover: #fff;
	--color-title: #7f7f8f;
	--color-year: #b9b3af;
	--img-ratio: 1.5;
	--s: 1;
}
.film {
  padding: 8vh 5vw 1vh;
  background: var(--color-bg) url("film-bg.jpg") no-repeat fixed top/100%;
	background-position: bottom 3% right 40%;
  color: white;

	.body {
		margin: 0;
		color: var(--color-text);
		font-family: "area-normal", sans-serif;
		font-weight: 600;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 100%;
		overflow-x: hidden;
	}
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.7,0.7,1);
	}
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
}

.grid {
	/* width: 100%; */
	grid-template-columns: 100%;
	grid-auto-rows: auto;
	display: grid;
	grid-gap: 4rem;
	position: relative;
	margin: 10vh 5vw 50vh;
}

.grid__item {
    position: relative;
    margin: 0;
}

.grid__item-img {
	position: relative;
	overflow: hidden;
	display: grid;
	place-items: center;
	width: 100%;
	height: auto;
	aspect-ratio: var(--img-ratio);
}

.grid__item-img-inner {
	width: 100%;
	height: 100%;
	background-position: 50%;
	background-size: cover;
	position: relative;
}

.grid__item-caption {
	position: absolute;
	padding: 0.5rem;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.grid__item-caption h3 {
	font-weight: bold;
	font-size: inherit;
	margin: 0;
	color: var(--color-title);
}

.grid__item-caption span {
	font-weight: bold;
	color: var(--color-year);
}

.btt {
	display:block;
	text-align: center;
}

.instagram-media {
	background: black;
	height: 50vh;
	max-width: 25vw;
	width: calc(100% - 2px);
	box-shadow: none;
	display: block;
	margin: -30vh auto 10vh;
	padding: 0px;
}
@media screen and (min-width: 53em) {
	.grid {
		grid-template-columns: repeat(8,1fr);
	}

	.grid__item {
		grid-column: var(--c) / span var(--s);
		grid-row: var(--r);
	}
}

@media only screen and (max-width: 600px) {
  .film {
    background: none;
  }
	.film:before {
		content: "";
		display: block;
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: -10;
		background: black url("film-bg.jpg") no-repeat center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	.grid {
		margin: 5vh 5vw 50vh;
	}
	.instagram-media {
		height: 48vh;
		max-width: 100vw;
		width: calc(100% - 2px);
	}
}