@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}
.main {
  height: 100%;
  padding-top: 4.5rem;
  padding-left: 10vw;
  padding-right: 10vw;
  background: black;
  color: white;

  .header {
    position: fixed;
    top: 0.5rem;
    left: calc(50vw - 7.5rem);
    font-family: "Sugar Cream";
    font-size: 5rem;
    letter-spacing: 1rem;
    z-index: 0;

    /* animation: glow 1s ease-in-out infinite alternate; */
    text-shadow: 0 0 15px #000;
  }

  .content {
    position: relative;
    z-index: 1;
    margin-top: 2.5rem;

    img {
      object-fit: cover;
      height: 100%;
      width: 60%;
      margin-top: -10%;
    }
    img:nth-child(odd) {
      margin-right: 40%;
    }
    img:nth-child(even) {
      margin-left: 40%;
    }
  }

  .body {
    background-color: white;
    min-height: 200vh;
  }

  .masthead {
    height: 110vh;
    overflow: hidden;
  }
  .masthead:after {
    background-color: rgba(0, 0, 0, .4);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
  }
}


