@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: "Sugar Cream";
  font-style: normal;
  font-weight: 900;
  src: url(webfonts/Sugar-Cream.otf);
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior-y: none;
  background: #f5f5f5;
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: black;
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

a.link {
  padding-right: 2vw;
}

a:hover {
  color: #00fff5;
}

.logo {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 45px;
  z-index: 999;
}
.logo:hover {
  cursor: pointer;
}